import React from "react";
import { graphql } from "gatsby";
import { Grid, Image } from "semantic-ui-react";
import Helmet from "react-helmet";

import {
  PageLayout,
  HeroElement,
  About,
  ContactForm,
} from "@fishrmn/fishrmn-components";

import emailCallback from "../utils/jobs-email.js";

import Layout from "../components/Layout";
import { getPageHeroImages } from "../utils/images";

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Sponsors</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Sponsors"}
              tagline={""}
              images={getPageHeroImages(pageComponents)}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
            columns="3"
          >
            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/mogrownlogo_large.png"
                as="a"
                target="_blank"
                href="https://missourigrownusa.com/"
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/Cultivate-KC-Logo.png"
                as="a"
                target="_blank"
                href="https://www.cultivatekc.org/"
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/KC_Gugs_logo.gif"
                as="a"
                target="_blank"
                href="https://www.blackurbangrowers.org/"
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/lisc_logo_primary_black.png"
                as="a"
                target="_blank"
                href="https://www.lisc.org/covid-19/small-business-assistance/small-business-relief-grants/verizon-small-business-recovery-fund/"
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/verizon_logo.png"
                as="a"
                target="_blank"
                href="https://www.lisc.org/covid-19/small-business-assistance/small-business-relief-grants/verizon-small-business-recovery-fund/"
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/MOR-logo-web.png"
                as="a"
                target="_blank"
                href="https://www.missouriorganic.com/"
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/nyfc_bw_logo_white.png"
                as="a"
                target="_blank"
                href="https://www.youngfarmers.org/"
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/City_of_Kansas_City_Logo.png"
                as="a"
                target="_blank"
                href="https://www.kcmo.gov/city-hall/departments/city-planning-development/brownfields"
              />
            </Grid.Column>

            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/KCCG_Logo.jpg"
                as="a"
                target="_blank"
                href="https://kccg.org/"
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/KC_Food_Hub_logo.jpg"
                as="a"
                target="_blank"
                href="https://thekcfoodhub.com/"
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Image
                src="https://fisherman.gumlet.io/public/opheliasbluevine/renditionDownload.jpeg"
                as="a"
                target="_blank"
                href="https://climategkc.org"
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
          address {
            street
            city
            state
            zip
          }
        }
      }
    }
    fishermanWebsitePage(title: { eq: "Jobs" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
